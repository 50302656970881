// font-onload.js v1
async function fontOnload(fontName, maxTime = Infinity, timeInterval = 10) {
	const startTime = performance.now()

	return new Promise((resolve, reject) => {
		setInterval(() => {
			const currentTime = performance.now(),
				elapsedTime = currentTime - startTime

			if (document.fonts.check("12px " + fontName)) {
				resolve(true)
			} else if (elapsedTime >= maxTime) {
				reject(false)
			}
		}, timeInterval)
	})
}

export default fontOnload