import "./Icon.scss"

const Icons = {
	ArrowPrev: function({className = "", fill = "none", stroke = "none", strokeWidth = 1.5, strokeLinecap = "round", strokeLinejoin = "round"}) {
		className = `icon icon-arrow-prev ${className}`.trim()
		return (
			<svg className={className} fill={fill} stroke={stroke} strokeWidth={strokeWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<path d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
			</svg>
		)
	},
	ArrowNext: function({className = "", fill = "none", stroke = "none", strokeWidth = 1.5, strokeLinecap = "round", strokeLinejoin = "round"}) {
		className = `icon icon-arrow-next ${className}`.trim()
		return (
			<svg className={className} fill={fill} stroke={stroke} strokeWidth={strokeWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<path d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinejoin} />
			</svg>
		)
	},
}

function Icon({icon, className = "", ...props}) {
	if(Icons[icon]) {
		return Icons[icon]({className, ...props})
	}
	return <div className={`icon ${className}`.trim()}>Invalid Icon</div>
}

export default Icon
