import "./UnderConstructionMobile.scss"

import { useState } from "react"

import Btn from "../Btn/Btn"

function UnderConstructionMobile() {
	const [hiddenModal, setHiddenModal] = useState(false),
		[opacityModal, setOpacityModal] = useState(true)

	function closeModal() {
		setOpacityModal(false)
		setTimeout(() => {
			setHiddenModal(true)
		}, 400)
	}

	let className = "grid slp:hidden place-items-center "
	className += `${opacityModal ? "opacity-1" : "opacity-0"} ${hiddenModal ? "hidden" : ""}`.trim()

	return (
		<div id="UnderConstructionMobile" className={className}>
			<div className="grid place-items-center">
				<h1 className="rainbow-color">Site em desenvolvimento.</h1>
				<p>
					A versão mobile do site está em desenvolvimento e não reflete o produto final. Para uma experiência completa use um computador ou a amplie a janela do navegador.
				</p>
				<Btn onClick={closeModal}>Ok</Btn>
			</div>

			<div className="background-color"></div>
		</div>
	)
}

export default UnderConstructionMobile
