import { useEffect } from "react"
import { useContextGlobal } from "../../js/globalContext"

import "./Portfolio.scss"

// complements
import { forNum } from "../../js/utils"

// components
// import PortfolioContent from "../PortfolioContent/PortfolioContent"
import PortfolioCarousel from "../PortfolioCarousel/PortfolioCarousel"
import Divider from "../Divider/Divider"

const originalWebsites = [
	{
		title: "Super Nosso (VTEX Legacy)",
		websiteName: "super-nosso-vtex-legacy",
		img: require("../../imgs/portfolio/optimized_supernossoemcasa.com.br.jpg")
	}, {
		title: "Super Nosso (VTEX)",
		websiteName: "super-nosso-vtex",
		img: require("../../imgs/portfolio/optimized_supernosso.com.br.jpg")
	}, {
		title: "Red Hat",
		websiteName: "red-hat",
		img: require("../../imgs/portfolio/optimized_redhat.com.br.jpg")
	}, {
		title: "Waterdrop (Fluig)",
		websiteName: "waterdrop-fluig",
		img: require("../../imgs/portfolio/optimized_waterdrop.fluig.com.jpg")
	}, {
		title: "Focus Têxtil",
		websiteName: "focus-textil",
		img: require("../../imgs/portfolio/optimized_focustextil.com.br.jpg")
	}, {
		title: "Camilinha Boutique Kids (EN/PT)",
		websiteName: "camilinha-boutique-kids-en-pt",
		img: require("../../imgs/portfolio/optimized_camilinhaboutiquekids.com.jpg")
	}, {
		title: "Snowflake (Fluig)",
		websiteName: "snowflake-fluig",
		img: require("../../imgs/portfolio/optimized_snowflake.fluig.com.jpg")
	}, {
		title: "Luis Lobo (v1)",
		websiteName: "luis-lobo-v1",
		img: require("../../imgs/portfolio/optimized_luislobo.com.br.jpg")
	},
	{
		title: "Cristina Sarôa",
		websiteName: "cristina-saroa",
		img: require("../../imgs/portfolio/optimized_cristinasaroa.com.br.jpg")
	}, {
		title: "Smart eSocial (TOTVS)",
		websiteName: "smart-esocial-totvs",
		img: require("../../imgs/portfolio/optimized_smartesocial.totvs.com.jpg")
	}, {
		title: "Fluig (EN/ES)",
		websiteName: "fluig-en-es",
		img: require("../../imgs/portfolio/optimized_en.fluig.com.jpg")
	}
]

forNum(3, () => { // reorder
	originalWebsites.unshift(originalWebsites.pop())
})

originalWebsites.forEach((website, index) => website.position = index) // add position

function Portfolio() {
	const [websites, setWebsites] = useContextGlobal("Portfolio.websites")

	useEffect(() => {
		if (!websites.length) {
			loadImgs()
			async function loadImgs() {
				const loadedImgs = await Promise.all(
					originalWebsites.map(({img}) => fetch(img))
				)

				const result = originalWebsites.map((website, index) => {
					const img = loadedImgs[index].url

					return {
						...website,
						img
					}
				})

				setWebsites(result)
			}
		}
	})

	return (
		<section id="portfolio" className="select-none">
			<Divider className="divider-top" />
			<div className="main-container grid grid-cols-12">
				<div className="col-span-12">
					<PortfolioCarousel />
				</div>
			</div>
			<Divider className="divider-bottom" />
		</section>
	)
}

export default Portfolio
