import "./App.scss"

// components
import UnderConstruction from "../UnderConstruction/UnderConstruction"
import UnderConstructionMobile from "../UnderConstructionMobile/UnderConstructionMobile"
import Favicon from "../Favicon/Favicon"
import Rainbow from "../Rainbow/Rainbow"
import Menu from "../Menu/Menu"
import Home from "../Home/Home"
import AboutMe from "../AboutMe/AboutMe"
import Portfolio from "../Portfolio/Portfolio"
import ArtificialIntelligence from "../ArtificialIntelligence/ArtificialIntelligence"
import Contact from "../Contact/Contact"
import CodeWriterOnBackground from "../CodeWriterOnBackground/CodeWriterOnBackground"

function App() {
	return (
		<>
			<UnderConstruction />
			<UnderConstructionMobile />
			<Favicon />
			<Rainbow />
			<Menu />
			<Home />
			<AboutMe />
			<Portfolio />
			<ArtificialIntelligence></ArtificialIntelligence>
			<Contact />
			<CodeWriterOnBackground />
		</>
	)
}

export default App
