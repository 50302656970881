import { useEffect, useRef } from "react"

import "./IconAnimated.scss"

// complements
import { activeElementAndExecute, isElementInDOM, isVisibleOnViewport } from "../../js/utils.js"
import { rafInterval } from "../../complements/raf-interval"
import animateSVGIconDrawMasking from "../../complements/animate-svg-icon-draw-masking.js"

function IconAnimated({iconObj, title, isActive = true}) {
	const blockRef = useRef(null),
		iconRef = useRef(null),
		iconReverseRef = useRef(null),
		{ iconImg, iconImgReverse } = iconObj

	useEffect(() => {
		const blockElement = blockRef.current

		if (isActive) {
			activeElementAndExecute(blockElement, () => {
				rafInterval((_, rafObj) => {
					if (isElementInDOM(blockElement)) {
						if (isVisibleOnViewport(blockElement)) {
							const iconElement = iconRef.current,
								config = iconObj.config,
								iconElementReverse = iconReverseRef.current

							animateSVGIconDrawMasking(iconElement, config, [iconElement, iconElementReverse])

							rafObj.stop()
						}
					} else {
						rafObj.stop()
					}
				}, 100)
			})
		}
	}, [blockRef, iconRef, iconReverseRef, iconObj, isActive])

	return (
		<div className="icon-animated block-animate-svg-icon-draw-masking" ref={blockRef}>
			<img className="animate-svg-icon-draw-masking" src={iconImg} alt={title} ref={iconRef} />
			<img className="hidden" src={iconImgReverse} alt={title} ref={iconReverseRef} />
		</div>
	)
}

export default IconAnimated
