const { rafInterval } = require("./raf-interval")

// raf-scroll.js v1.4, dependence: raf-interval.js
const rafObj = {
	stop: () => {}
}

function rafScroll(element, position, duration = 700) {
	position = {
		x: position.x !== undefined ? position.x : window.scrollX,
		y: position.y !== undefined ? position.y : window.scrollY
	}

	rafObj.stop()
	const {
		scrollLeft: startPositionX,
		scrollTop: startPositionY
	} = element,
		distanceX = position.x - startPositionX,
			distanceY = position.y - startPositionY,
			endPositionX = startPositionX + distanceX,
			endPositionY = startPositionY + distanceY,
			maxDistance = Math.max(Math.abs(distanceX), Math.abs(distanceY))
		duration = Math.min(maxDistance, 700)

	requestAnimationFrame(startTime => {
		rafInterval((currentTime, {stop: stopAnimation}) => {
			rafObj.stop = stopAnimation
			const elapsedTime = currentTime - startTime,
				newPositionX = linearAnimation(elapsedTime, duration, startPositionX, distanceX),
				newPositionY = linearAnimation(elapsedTime, duration, startPositionY, distanceY)
			element.scrollTo(newPositionX, newPositionY)

			if (elapsedTime > duration) {
				element.scrollTo(endPositionX, endPositionY)
				stopAnimation()
			}
		})
	})

	function linearAnimation(elapsedTime, duration, startPosition, distance) {
		const timeRatio = duration === 0 ? 1 : elapsedTime / duration
		return startPosition + distance * timeRatio
	}
}

if (typeof(module) === "object" && typeof(module.exports) === "object") {
	module.exports = rafScroll
}