import "./ScreenFlag.scss"

import { useState, useEffect } from "react"

function getScreenName() {
	const screenSize = window.innerWidth

	if (screenSize < 576) {
		return "xsmb"
	} else if (screenSize < 768) {
		return "smb"
	} else if (screenSize < 1080) {
		return "mb"
	} else if (screenSize < 1280) {
		return "slp"
	} else if (screenSize < 1367) {
		return "lp"
	} else if (screenSize < 1681) {
		return "sdt"
	} else if (screenSize < 1921) {
		return "dt"
	}
	return "ldt"
}

function getOrientation() {
	return window.innerWidth <= window.innerHeight ? "portrait" : "landscape"
}

function ScreenFlag() {
	const [screenSize, setScreenSize] = useState(window.innerWidth),
		[screenName, setScreenName] = useState(getScreenName()),
		[orientation, setOrientation] = useState(getOrientation()),
		isDev = window.location.hostname === "localhost"

	useEffect(() => {
		function handleResize() {
			setScreenSize(window.innerWidth)
			setScreenName(getScreenName())
			setOrientation(getOrientation())
		}
		
		window.addEventListener("resize", handleResize)
	
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return (
		<>
			{isDev &&
				<div id="screen-flag" className="rainbow-bg">
					<span>{screenName} - {screenSize}px ({orientation})</span>
				</div>
			}
		</>
	)
}

export default ScreenFlag
