import { useEffect, useRef } from "react"

import "./ArtificialIntelligence.scss"

// complements
import { forNum, limiter, ruleOfThree } from "../../js/utils"

// components
import IconAnimated from "../IconAnimated/IconAnimated"

import IconAi from "../../imgs/icons/ai.svg"
import IconAiReverse from "../../imgs/icons/ai-reverse.svg"

function ArtificialIntelligence() {
	const blockGridRef = useRef(null),
		blockGrid2Ref = useRef(null),
		parallaxRef = useRef(null)

	const imgLength = 22,
		imgs = []

	forNum(imgLength, index => {
		index++
		imgs.push(require(`../../imgs/artificial-intelligence/${index}.jpg`))
	})

	let iconConfig = {
		ai: {
			fill: "none",
			stroke: "black",
			strokeWidth: 14,
			maxDuration: 3000,
			delay: 1000
		}
	}
	iconConfig.ai = [
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai,
			className: "rainbow-stroke"
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		},
		{
			...iconConfig.ai
		}
	]

	const iconObj = {
		iconImg: IconAi,
		iconImgReverse: IconAiReverse,
		config: iconConfig.ai
	}

	useEffect(() => {
		const blockGrid = blockGridRef.current,
			blockGrid2 = blockGrid2Ref.current,
			parallax = parallaxRef.current,
			events = ["scroll", "resize"]

		if (blockGrid && blockGrid2 && parallax) {
			events.forEach(eventName => {
				window.addEventListener(eventName, parallaxScrolling)
			})
		}

		function parallaxScrolling() {
			const blockGrid = blockGridRef.current,
				blockGrid2 = blockGrid2Ref.current,
				parallax = parallaxRef.current

			const speed = -2.5,
				viewportHeight = window.innerHeight,
				rect = parallax.getBoundingClientRect(),
				viewportY = {
					min: 0,
					mid: viewportHeight / 2,
					max: viewportHeight
				},
				elementY = {
					min: rect.y,
					mid: rect.y + rect.height / 2,
					max: rect.height / 2
				}

			const midDiff = viewportY.mid - elementY.mid,
				midDiffPercent = limiter(ruleOfThree(viewportHeight, 100, midDiff), -100, 100),
				top = midDiffPercent * speed

			if (top >= 0) {
				blockGrid.style.marginTop = `${top}vh`
				blockGrid2.style.marginBottom = `0vh`
			} else {
				blockGrid.style.marginTop = `0vh`
				blockGrid2.style.marginBottom = `${Math.abs(top)}vh`
			}
		}

		return () => {
			events.forEach(eventName => {
				window.removeEventListener(eventName, parallaxScrolling)
			})
		}
	}, [blockGridRef, parallaxRef])

	return (
		<section id="artificial-intelligence" className="main-container grid-center grid-cols-2">
			<div className="grid-center">
				<div className="w-full px-0 slp:px-5 sdt:px-10">
					<IconAnimated iconObj={iconObj} title="Inteligência Artificial" />
					<h1 className="global-title">
						Inteligência <br/>
						Artificial
					</h1>
					<div className="hr rainbow-bg"></div>
					<p className="text">
						Tenho conhecimentos sólidos em geração de imagens com IA conhecendo as principais ferramentas do mercado e acompanhando as novidades com frequência.<br/>
						Não possuo curso na área pois o que eu tenho aprendido ainda não está sendo ensinado pelos mesmos, então eu adquiro meus conhecimentos em artigos sobre o tema, vídeos soltos e muita prática.
					</p>
				</div>
			</div>
			<div>
				<div className="parallax grid-center w-full h-full" ref={parallaxRef}>
					<div className="block-grid grid place-items-center grid-cols-3 gap-10">
						<div className="col-span-3" ref={blockGridRef}></div>
						{imgs.map(url => (
							<div key={url} className="img-blur">
								<img src={url} alt="img" />
								<img src={url} alt="img" />
							</div>
						))}
						{imgs.map(url => (
							<div key={url} className="img-blur">
								<img src={url} alt="img" />
								<img src={url} alt="img" />
							</div>
						))}
						<div className="col-span-3" ref={blockGrid2Ref}></div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ArtificialIntelligence
