import { createContext, useContext, useState } from "react"

const GlobalContext = createContext(),
	{ Provider } = GlobalContext

const protocol = "https://",
	contacts = {
		email: "contato@luislobo.com.br",
		phone: "5511994370950",
		phoneDOM: "55 (11) 9 9437-0950"
	}

	contacts.socialMediaPlataforms = [
		{
			name: "E-mail",
			user: contacts.email,
			url: `mailto:${contacts.email}`
		},
		{
			name: "Telefone",
			user: contacts.phone,
			userDOM: contacts.phoneDOM
		},
		{
			name: "Discord",
			url: `${protocol}discord.com/`,
			user: "luislobo9b"
		},
		{
			name: "GitHub",
			url: `${protocol}github.com/luislobo9b/`,
			user: "luislobo9b"
		},
		{
			name: "Instagram",
			url: `${protocol}instagram.com/luislobolfld/`,
			user: "luislobolfld"
		},
		{
			name: "LinkedIn",
			url: `${protocol}linkedin.com/in/luislobo9b/`,
			user: "luislobo9b"
		},
		{
			name: "Linktree",
			url: `${protocol}linktr.ee/luislobo9b/`,
			user: "luislobo9b"
		},
		{
			name: "Messenger",
			url: `${protocol}m.me/luis.lobo9b/`,
			user: "luis.lobo9b"
		},
		{
			name: "Telegram",
			url: `${protocol}t.me/luislobo9b/`,
			user: "luislobo9b"
		},
		{
			name: "WhatsApp",
			url: `${protocol}wa.me/${contacts.phone}/`,
			user: contacts.phone,
			userDOM: contacts.phoneDOM
		}
	]

const GlobalProvider = ({ children }) => {

	const providerValue = {
		contacts: useState(contacts),
		fontName: useState("Figtree"),
		_Slick: {
			currentSlider: useState(0)
		},
		AboutMe: {
			currentSlider: useState(0)
		},
		Portfolio: {
			websites: useState([])
		}
	}

	return (
		<Provider value={providerValue}>
			{children}
		</Provider>
	)
}

const useContextGlobal = stateName => {
	stateName = stateName.split(".")
	let context = useContext(GlobalContext)

	for(let i = 0; i < stateName.length; i++){
		context = context[stateName[i]]
	}

	return context
}

export {
	GlobalProvider,
	useContextGlobal
}
