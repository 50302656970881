import { useContextGlobal } from "../../js/globalContext"

import "./SocialContacts.scss"

import Tippy from "@tippyjs/react"

import HomeSpacer from "../HomeSpacer/HomeSpacer"

function IconEmail({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-stroke-hover">
				<svg className="icon-email" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.25} stroke="#ffffff">
					<path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
				</svg>
			</div>
		</Tippy>
	)
}

function IconTelefone({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-stroke-hover">
				<svg className="icon-smartphone" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.25} stroke="#ffffff">
					<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
				</svg>
			</div>
		</Tippy>
	)
}

function IconWhatsApp({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-fill-hover">
				<svg className="icon-whatsapp" version="1.2" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 64 62">
					<path fillRule="evenodd" d="m54.7 9.1c6 5.8 9.3 13.4 9.3 21.7 0 16.9-14.2 30.6-31.8 30.6q-2.1 0-4.1-0.2-1.9-0.2-3.7-0.6-2.1-0.5-4-1.3-1.8-0.6-3.5-1.6l-16.9 4.3 4.5-15.9c-2.8-4.8-4.3-9.9-4.3-15.3 0-17 14.5-30.8 32-30.8 8.5 0 16.5 3.3 22.5 9.1zm3.9 21.7c0-6.8-2.7-13.2-7.8-18.2-5-4.7-11.6-7.4-18.6-7.4-14.7 0-26.6 11.5-26.6 25.6q0 1.6 0.4 3.5 0.2 1.9 0.6 3.5 0.6 1.7 1.5 3.3 0.6 1.7 1.6 3.1l0.6 1.1-2.7 9.5 10.2-2.7 0.8 0.6c4.1 2.3 8.9 3.5 13.6 3.5 14.5 0 26.4-11.4 26.4-25.4zm-10.5 7.2c0.2 0.4 0.2 1.9-0.4 3.7-0.6 1.7-3.7 3.4-5.4 3.6-1.4 0.2-3.1 0.4-4.9-0.2-1.3-0.5-2.7-0.9-4.6-1.7-8-3.3-13.2-11-13.6-11.6-0.4-0.4-3.3-4.1-3.3-7.8 0-4 2.1-5.8 2.9-6.4 0.6-0.9 1.4-1.1 2.1-1.1q0.8 0 1.4 0c0.6 0 1.2-0.2 1.9 1.3 0.6 1.6 2.2 5.3 2.4 5.8 0.2 0.4 0.4 0.8 0 1.4-0.2 0.4-0.4 0.8-0.8 1.2-0.4 0.5-0.8 1.1-1 1.3-0.4 0.4-0.9 0.8-0.4 1.6 0.4 0.9 2 3.3 4.3 5.4 3.1 2.5 5.8 3.3 6.4 3.7 0.8 0.4 1.4 0.4 1.9-0.2 0.4-0.4 1.8-2.2 2.4-2.9 0.7-0.8 1.1-0.8 1.9-0.4 0.6 0.2 4.5 2.1 5.4 2.5 0.8 0.4 1.2 0.6 1.4 0.8z"/>
				</svg>
			</div>
		</Tippy>
	)
}

function IconGitHub({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-fill-hover">
				<svg className="icon-github" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#ffffff">
					<path fillRule="evenodd" d="m32 0c-17.7 0-32 14.3-32 32 0 14.2 9.2 26.1 21.9 30.4 1.6 0.2 2.2-0.7 2.2-1.6 0-0.7-0.1-3.2-0.1-5.9-8 1.5-10.1-2-10.7-3.8-0.4-0.9-1.9-3.7-3.3-4.5-1.1-0.6-2.7-2.1 0-2.1 2.5-0.1 4.3 2.3 4.9 3.3 2.9 4.8 7.5 3.4 9.3 2.6 0.3-2.1 1.1-3.5 2-4.3-7.1-0.8-14.5-3.5-14.5-15.8 0-3.5 1.2-6.3 3.3-8.6-0.4-0.8-1.5-4.1 0.3-8.5 0 0 2.7-0.8 8.8 3.3 2.5-0.7 5.3-1.1 8-1.1 2.7 0 5.4 0.4 8 1.1 6.1-4.1 8.8-3.3 8.8-3.3 1.7 4.4 0.6 7.7 0.3 8.5 2 2.3 3.3 5.1 3.3 8.6 0 12.3-7.5 15-14.6 15.8 1.1 1 2.1 2.9 2.1 5.9 0 4.3 0 7.8 0 8.8 0 0.9 0.6 1.9 2.2 1.6 3.2-1.1 6.1-2.7 8.8-4.6 2.7-2 5-4.4 7-7.1 1.9-2.7 3.4-5.7 4.5-8.9 1-3.2 1.5-6.5 1.5-9.8 0-17.7-14.3-32-32-32z"/>
				</svg>
			</div>
		</Tippy>
	)
}

function IconLinkedIn({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-fill-hover">
				<svg className="icon-linkedin" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#ffffff">
					<path fillRule="evenodd" d="m0 5c0-2.8 2.2-5 5-5h54c2.8 0 5 2.2 5 5v54c0 2.8-2.2 5-5 5h-54c-2.8 0-5-2.2-5-5zm24.4 49.9h9.1v-14.7c0-5.1 2.2-8.2 6.8-8.2 3.8 0 5.4 3.6 5.4 7.6v15.3h9.2v-17c0-9-2.6-13.9-11.8-13.9-4.8 0-8.7 2.4-10 4.8h-0.1v-4.4h-8.6zm-15.3 0h9.2v-30.5h-9.2zm10.3-41.1c0-3.2-2.5-5.8-5.7-5.8-3.1 0-5.7 2.6-5.7 5.8 0 3.1 2.6 5.7 5.7 5.7 3.2 0 5.7-2.6 5.7-5.7z"/>
				</svg>
			</div>
		</Tippy>
	)
}

function IconLinktree({tippy}) {
	return (
		<Tippy content={tippy} placement="right">
			<div className="social-icon rainbow-fill-hover">
				<svg className="icon-linktree" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 64" fill="#ffffff">
					<path fillRule="evenodd" d="m16.1 21.7l-11.3-10.6 6.3-6.3 10.7 10.9v-15.5h9.4v15.5l10.8-10.9 6.2 6.3-11.3 10.6h15.9v8.8h-16l11.4 11-6.3 6.1-15.4-15.4-15.5 15.4-6.2-6.1 11.3-11h-16v-8.8zm5.8 21.4h9.3v20.8h-9.3z"/>
				</svg>
			</div>
		</Tippy>
	)
}

function SocialContacts() {
	const [contacts] = useContextGlobal("contacts"),
		iconComponents = [
			IconEmail,
			IconTelefone,
			IconWhatsApp,
			IconGitHub,
			IconLinkedIn,
			IconLinktree
		],
		plataforms = ["E-mail", "Telefone", "WhatsApp", "GitHub", "LinkedIn", "Linktree"],
		socialMediaPlataforms = plataforms.map((plataform, index) => { // order
			plataform = contacts.socialMediaPlataforms.find(({name}) => name === plataform)
			plataform.IconComponent = iconComponents[index]
			return plataform
		})

	return (
		<div id="social-contacts" className="grid-center">
			<div className="w-full">
				{socialMediaPlataforms.map(({name, user, userDOM, url, IconComponent}, index) => {
					if (url) {
						return (
							<a key={index} className="cursor-pointer" href={url} target="_blank" rel="noreferrer">
								<IconComponent tippy={`${name}: ${userDOM || user}`} />
							</a>
						)
					} else {
						return <IconComponent key={index} tippy={`${name}: ${userDOM || user}`} />
					}
				})}
				<HomeSpacer />
			</div>
		</div>
	)
}

export default SocialContacts
