// animate-svg-icon-draw-masking.js v0.2 beta
import { rafInterval } from "./raf-interval.js"

async function animateSVGIconDrawMasking(element, configs, srcs) {
	[configs, srcs] = normalize(element, configs, srcs)
	const mainDiv = element.parentElement,
		{ src } = element,
		[svg, svgReverse] = await getSvgs(element, srcs)
	mainDiv.style.webkitMaskImage = `url(${src})`
	mainDiv.style.maskImage = `url(${src})`

	const isGlobal = configs[0].isGlobal,
		paths = [...svg.querySelectorAll("[d]")],
		pathsReverse = [...svgReverse.querySelectorAll("[d]")],
		pathList = [...paths, ...pathsReverse],
		animatedPathList = []

	if (isGlobal) { // copy config: [config] to [config, config, config, ...]
		const config = configs[0]
		forNum(paths.length, index => {
			configs[index] = {
				...config,
				pathIndex: index
			}
		})
	}
	const pathIndexList = configs.map(config => config.pathIndex),
		maxPathIndex = Math.max(...pathIndexList)
	if (maxPathIndex >= paths.length) {
		throw new Error(`The SVG has ${paths.length} paths, but there is a larger value (${maxPathIndex}) define in the 'index' of some object.`)
	}
	configs.forEach(config => {
		const {
			pathIndex,
			maxDuration
		} = config,
		path = paths[pathIndex],
			pathReverse = pathsReverse[pathIndex],
			pathLength = Math.max(path.getTotalLength(), pathReverse.getTotalLength())
		config.path = path
		config.pathReverse = pathReverse
		config.pathLength = pathLength

		// set as animated path
		animatedPathList.push(path, pathReverse)
		if (config.speed === "auto") {
			config.speed = config.pathLength / maxDuration
		}
		setAttributesOnPaths(config)
	})
	mainDiv.insertBefore(svgReverse, element)
	mainDiv.insertBefore(svg, element)

	// set non-animated items to transparent (stroke: none; fill: none;)
	pathList.filter(path => !animatedPathList.includes(path)).forEach(path => {
		path.style.stroke = "none"
		path.style.fill = "none"
	})
	return runAnimation(configs)

	function runAnimation(configs) {
		return new Promise(resolve => {
			let countResolvedPromisses = 0
			configs.forEach(config => {
				const {
					// attributes
					strokeLinecap,
					// other options
					path,
					pathReverse,
					pathLength,
					delay,
					speed,
					maxDuration
				} = config

				// rafDelay
				rafInterval((startTime, rafDelayObj) => {
					rafDelayObj.stop()
					path.setAttribute("stroke-linecap", strokeLinecap)

					rafInterval((currentTime, rafObj) => {
						const elapsedTime = currentTime - startTime,
							currentLength = Math.min(elapsedTime * speed, pathLength)
						path.setAttribute("stroke-dasharray", `${currentLength},${pathLength}`)
						pathReverse.setAttribute("stroke-dasharray", `${currentLength},${pathLength}`)
						if (currentLength === pathLength || elapsedTime >= maxDuration) { // finish animation
							path.setAttribute("stroke-dasharray", `${pathLength},${pathLength}`)
							pathReverse.setAttribute("stroke-dasharray", `${pathLength},${pathLength}`)
							rafObj.stop()
							countResolvedPromisses++
							if (countResolvedPromisses === configs.length) {
								resolve(configs)
							}
						}
					}, 0)
				}, delay)
			})
		})
	}

	function normalize(element, configs, srcs) {
		if (!hasMaskSupport()) {
			throw new Error("The browser does not support mask, mask-image or mask-size CSS.")
		}

		const isElement = element && element.nodeType && element.nodeType === 1
		if (!isElement) {
			throw new Error("The first argument must be <img> element.")
		}

		const { tagName, src } = element
		if (tagName !== "IMG") {
			throw new Error("The element must be <img> with the 'src' attribute pointing to a '.svg' vector.")
		}
		if (!/\.svg$/i.test(src)) {
			throw new Error("The 'src' attribute must have a url ending with '.svg' and cannot be any other way.")
		}
		if (!Array.isArray(configs)) {
			configs = [configs]
		}
		const defaultConfigs = {
			// attributes
			fill: "auto",
			stroke: "auto",
			strokeWidth: "auto",
			strokeDashoffset: "auto",
			strokeLinejoin: "auto",
			strokeLinecap: "auto",

			// other options
			// className
			// path,
			// pathReverse,
			// pathIndex,
			// pathLength,
			// src,
			// srcReverse,
			delay: 0,
			speed: "auto",
			maxDuration: Infinity
		}
		configs = configs.map(config => {
			if (config.index !== undefined) { // rename index to pathIndex
				config.pathIndex = config.index
				delete config.index
			}
			if (config.speed === "auto" && config.maxDuration === undefined) {
				throw new Error("The value of 'speed' can only be 'auto' if you set maxDuration.")
			}
			return {
				...defaultConfigs,
				...config
			}
		})

		// pathIndex
		const configsLength = configs.length
		let pathIndexLength = configs.filter(config => config.pathIndex !== undefined).length,
			isGlobal = configsLength === 1 && pathIndexLength === 0

		if (pathIndexLength === 0) { // add order
			configs = configs.map((config, index) => {
				config.pathIndex = index
				return config
			})
			pathIndexLength = configs.length
		}

		if (isGlobal) {
			const config = configs[0]
			config.isGlobal = true
		}
		if (!isGlobal && configs.length !== pathIndexLength) {
			throw new Error("When you use 'index' property on any object, it is required for all of them.")
		} else {
			const pathIndexList = configs.map(config => config.pathIndex),
				minPathIndex = Math.min(...pathIndexList)
			if (pathIndexList.length !== arrayUnique(pathIndexList).length) {
				throw new Error("There are two objects with the same value in 'index'.")
			}
			if (minPathIndex < 0) {
				throw new Error(`The index of elements has a minimum value of zero, but some object is using a lower value (${minPathIndex}).`)
			}
		}
		if (Array.isArray(srcs)) {
			if (srcs.length !== 2) {
				throw new Error(`The third argument, 'srcs', must contain two <img> elements or their respective attributes 'src'.`)
			}
			let element = srcs[0],
				elementReverse = srcs[1],
				src = typeof(element) === "string" ? element : element.src,
				srcReverse = typeof(elementReverse) === "string" ? elementReverse : elementReverse.src
			srcs = [src, srcReverse]
		} else {
			srcs = [undefined, undefined]
		}
		return [configs, srcs]
	}
	async function getSvgs({ src }, srcs) {
		const regexSvg = /\.svg$/i
		const srcReverse = src.replace(regexSvg, match => {
				return "-reverse" + match
			}),
			response = await fetch(srcs[0] || src),
			svgText = await response.text(),
			svg = parseHtml(svgText),
			responseReverse = await fetch(srcs[1] || srcReverse),
			svgTextReverse = await responseReverse.text(),
			svgReverse = parseHtml(svgTextReverse)
		svg.classList.add("svg-animate-svg-icon-draw-masking")
		svgReverse.classList.add("svg-animate-svg-icon-draw-masking")
		return [svg, svgReverse]
	}

	function setAttributesOnPaths(config) {
		const paths = [config.path, config.pathReverse]
		paths.forEach(path => {
			["fill", "stroke", "strokeWidth", "strokeDashoffset", "strokeLinejoin"].forEach(attr => {
				const isAuto = config[attr] === "auto"
				if (isAuto) {
					path.style[attr] = getComputedStyle(path)[attr] || ""
				} else {
					path.style[attr] = config[attr]
				}
			})
			path.setAttribute("stroke-dasharray", `0,${config.pathLength}`)

			if (config.className) {
				let className = config.className
				if (typeof(className) === "string") {
					className = [className]
				}
				className = className.map(thisClassName => thisClassName.replace(/\s+/g, " ").trim()).join(" ")

				if (config.className[0] === "+") {
					className = config.className.substring(1)
				} else {
					path.removeAttribute("class")
				}

				className.split(/\s+/g).forEach(thisClassName => {
					path.classList.add(thisClassName)
				})
			}
		})
	}

	function hasMaskSupport() {
		const style = document.createElement("div").style,
			mask = "mask" in style || "webkitMask" in style,
			maskImage = "maskImage" in style || "webkitMaskImage" in style,
			maskSize = "maskSize" in style || "webkitMaskSize" in style
		return mask && maskImage && maskSize
	}

	function forNum(n, fn) {
		for (let i = 0; i < n; i++) {
			fn(i, n)
		}
	}

	function arrayUnique(array) {
		return array.filter((item, index) => {
			return array.findIndex(thisItem => item === thisItem) === index
		})
	}

	function parseHtml(html) {
		const template = document.createElement("template")
		template.innerHTML = html
		const elements = template.content.children
		return elements.length === 1 ? elements[0] : elements
	}
}

export default animateSVGIconDrawMasking
