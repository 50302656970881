// raf-interval.js v1
function rafInterval(fn, timeInterval = 0) {
	const rafObj = {
		running: false,
		stopped: false,
		paused: false,
		timeInterval,
		play: (noResetTime = false) => {
			rafObj.paused = false
			if (!noResetTime) {
				setStartTime(runRaf)
			} else {
				runRaf()
			}
		},
		pause: (forcePause = false) => {
			if (forcePause) {
				cancelRaf()
			}
			rafObj.paused = true
		},
		stop: (forceStop = false) => {
			if (forceStop) {
				cancelRaf()
			}
			rafObj.stopped = true
		}
	}

	setStartTime(runRaf)

	function runRaf() {
		rafObj.animationId = requestAnimationFrame(loop)
	}

	function cancelRaf() {
		cancelAnimationFrame(rafObj.animationId)
	}

	function setStartTime(callback) {
		requestAnimationFrame(startTime => {
			rafObj.startTime = startTime
			callback()
		})
	}

	function loop(currentTime) {
		const { startTime, running, stopped, paused, timeInterval } = rafObj

		if (!stopped && !paused) {
			if (currentTime - startTime >= timeInterval) {
				rafObj.startTime = currentTime
				if (!running) {
					rafObj.running = true
					fn(currentTime, rafObj)
					rafObj.running = false
				}
			}
			runRaf()
		}
	}

	return rafObj
}

if (typeof(module) === "object" && typeof(module.exports) === "object") {
	module.exports = {
		rafInterval
	}
}