import { useRef, useEffect } from "react"

import "./AboutMeContent.scss"

// complements
import rafScroll from "../../complements/raf-scroll.js"
import overlayScrollbarsClickEvent from "../../complements/overlayscrollbars-click-event.js"

// libs
import { OverlayScrollbars } from "overlayscrollbars"

// components
import AboutMeCarousel from "../AboutMeCarousel/AboutMeCarousel"

function AboutMeContent() {
	const mainElement = useRef(null)

	useEffect(() => {
		const scrollbarsMainElement = OverlayScrollbars(mainElement.current, {})
		let { scrollbarVertical, scrollbarHorizontal } = scrollbarsMainElement.elements()
		scrollbarVertical = scrollbarVertical.track
		scrollbarHorizontal = scrollbarHorizontal.track

		scrollbarVertical.classList.add("hover")
		scrollbarHorizontal.classList.add("hover")

		overlayScrollbarsClickEvent(scrollbarsMainElement, {
			OverlayScrollbars,
			rafScroll
		})
	}, [])

	return (
		<div id="about-me-content" className="xsmb:mt-10 slp:mt-0" ref={mainElement}>
			<AboutMeCarousel />
		</div>
	)
}

export default AboutMeContent
