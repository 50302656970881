import "./HomeBg.scss"

function HomeBg() {
	return (
		<div id="home-bg">
			<div className="img-bg"></div>
			<div className="gradient-bg"></div>
		</div>
	)
}

export default HomeBg
