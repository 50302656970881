import { useContextGlobal } from "../../js/globalContext"

import { useRef, useState } from "react"

import { setTimesout } from "../../js/utils.js"

// libs
import emailjs from "@emailjs/browser"
import Swal from "sweetalert2"

import "./Contact.scss"

// components
import Tippy from "@tippyjs/react"
import GlowShadow from "../GlowShadow/GlowShadow"
import Btn from "../Btn/Btn"
import Icon from "../Icon/Icon"
import Scramble from "../Scramble/Scramble"
import Divider from "../Divider/Divider"

const plataforms = ["WhatsApp", "Discord", "Messenger", "LinkedIn", "GitHub", "Instagram"], // order
	imgIcons = plataforms.map(plataform => require(`../../imgs/icons/${plataform.toLowerCase()}.svg`))

function Contact() {
	const [contacts] = useContextGlobal("contacts"),
			socialMediaPlataforms = plataforms.map((socialMediaPlataform, index) => {
				const plataform = contacts.socialMediaPlataforms.find(({name}) => name === socialMediaPlataform)
				plataform.imgIcon = imgIcons[index]
				return plataform
			}),
			textList = ["Trabalhar", "Construir", "Inovar", "Evoluir", "Transformar", "Criar", "Conquistar"]

	const form = useRef(null),
		btnToCloneWrapper = useRef(null)

	const [userName, setUserName] = useState(""),
		[userEmail, setUserEmail] = useState(""),
		[message, setMessage] = useState("")

	function sendEmail(event) {
		event.preventDefault()

		const templateParams = {
			"source-domain": window.location.origin,
			"user-name": userName,
			"user-email": userEmail,
			"message": message
		}

		const publicKey = "K3E9Vps5MOCo7-8Wl",
			serviceID = "service_9e8z6r9",
			templateID = "template_kxhn568",
			options = {
				publicKey
			}

		emailjs.send(serviceID, templateID, templateParams, options).then(formSuccess, formError)
	}

	function formSuccess() {
		form.current.reset()

		Swal.fire({
			icon: "success",
			title: "E-mail enviado!",
			text: "Obrigado! Responderei assim que possível! 😆",
			confirmButtonText: "Ok"
		})

		swalBtnToBtnComponent()
	}

	function formError(response) {
		console.error(response)
		Swal.fire({
			icon: "error",
			title: "Ops!",
			text: "O e-mail não pode ser enviado 😣. Por favor, tente as outras formas de contato disponíveis.",
			confirmButtonText: "Ok"
		})
		swalBtnToBtnComponent()
	}

	function swalBtnToBtnComponent() {
		const selector = ".swal2-container button.swal2-confirm, .swal2-container .glow-shadow"

		setTimesout(() => {
			if (btnToCloneWrapper && btnToCloneWrapper.current && btnToCloneWrapper.current.children[0]) {
				const btnModal = document.querySelector(selector),
					btnToClone = btnToCloneWrapper.current.children[0]

				if (btnModal.tagName === "BUTTON") { // run only once
					btnModal.outerHTML = btnToClone.outerHTML

					const newBtnModal = document.querySelector(selector)

					newBtnModal.addEventListener("click", () => {
						Swal.close()
					})
				}
			}
		}, [0, 111, 222, 333])
	}

	function handleUserName(event) {
		setUserName(event.target.value)
	}
	function handleUserEmail(event) {
		setUserEmail(event.target.value)
	}
	function handleMessage(event) {
		setMessage(event.target.value)
	}

	return (
		<section id="contact">
			<Divider className="divider-top" />
			<div className="main-container slp:grid slp:place-items-center py-7">
				<div className="box-text">
					<h1 className="global-title color-white">Vamos</h1>
					<Scramble className="global-title rainbow-color" textList={textList} />
					<h1 className="global-title color-white last">Juntos?</h1>
				</div>

				<div className="box-neon box-form">
					<h5 className="global-title">Formulário</h5>
					<div className="hr rainbow-bg"></div>

					<form id="form" className="grid-center" ref={form} onSubmit={sendEmail}>
						<input type="text" name="user-name" placeholder="Nome" onInput={handleUserName} required />
						<input type="email" name="user-email" placeholder="E-mail" onInput={handleUserEmail} required />
						<textarea rows="5" name="message" placeholder="Assunto" onInput={handleMessage} required></textarea>

						<div className="hidden" ref={btnToCloneWrapper}>
							<GlowShadow className="inline-block">
								<Btn className="rainbow-bg">
									<span className="color-white">Ok</span>
								</Btn>
							</GlowShadow>
						</div>

						<GlowShadow>
							<Btn className="btn-dark rainbow-bg flip-h">
								<Icon className="flip-h" icon="ArrowNext" stroke="white" />
								<span className="flip-h color-white">Enviar</span>
							</Btn>
						</GlowShadow>
					</form>
				</div>

				<div className="box-neon box-availability">
					<h5 className="global-title">Disponibilidade</h5>
					<div className="hr rainbow-bg"></div>

					<p>
						<span className="dot active"></span>
						<span>Imediata</span>

						<span className="dot active"></span>
						<span>PJ</span>

						<span className="dot active"></span>
						<span>Freela</span>

						<span className="hidden">
							<br />
							<span className="dot"></span>
							<span>CLT</span>

							<span className="dot"></span>
							<span>Presencial</span>
						</span>
					</p>
				</div>

				<div className="box-neon box-contacts">
					<h5 className="global-title">Contatos</h5>
					<div className="hr rainbow-bg"></div>

					<p>
						<a href={`mailto:${contacts.email}`} target="_blank" rel="noreferrer">
							{contacts.email}
						</a>
					</p>
					<p>{contacts.phoneDOM.replace("55 ", "")}</p>
				</div>

				<div className="box-neon box-social-media-plataforms">
					<h5 className="global-title">Redes sociais</h5>
					<div className="hr rainbow-bg"></div>

					<div className="block-icons grid grid-cols-6 grid-rows-1 slp:grid-cols-3 slp:grid-rows-2">
						{socialMediaPlataforms.map(({name, url, user, userDOM, imgIcon}) => {
							const maskStyle = {
								WebkitMask: `url(${imgIcon})`,
								mask: `url(${imgIcon})`
							}

							return (
								<Tippy key={name} content={`${name}: ${userDOM || user}`} placement="top">
									<div className="icon-grid grid-center">
										<div className="rainbow-fill-on-hover bg-white" style={maskStyle}>
											<a className="cursor-pointer" href={url} target="_blank" rel="noreferrer">
												<img className="img-icon" src={imgIcon} alt={name} />
											</a>
										</div>
									</div>
								</Tippy>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Contact
