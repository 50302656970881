import { useEffect } from "react"

import { forNum } from "../../js/utils"

function Favicon() {
	const faviconElement = document.querySelector(`link[rel="icon"]`),
		imgLength = 8,
		imgs = []

	let originalTitle = document.title.trim()
	const titleLength = originalTitle.length,
		separator = " ..... "

	originalTitle = originalTitle + separator + originalTitle

	forNum(imgLength, index => {
		imgs.push(require(`../../imgs/favicon/${index}.png`))
	})

	useEffect(() => {
		let index = 0,
			titleIndex = 0

		const intervalId = setInterval(() => {
			index = index % (imgLength)
			titleIndex = titleIndex % (titleLength + separator.length + 2)
			faviconElement.setAttribute("href", imgs[index])
			index++

			document.title = originalTitle.substring(titleIndex)
			titleIndex++
		}, 333)

		return () => {
			clearInterval(intervalId)
		}
	})

	return (
		<></>
	)
}

export default Favicon