import "./Rainbow.scss"

// complements
import { forNum, repeatFor } from "../../js/utils.js"

const { body } = document,
	colorsLength = 9

const rainbowColorNames = [
	"neon-pink",
	"red",
	"orange",
	"lime-green",
	"neon-green",
	"aqua-blue",
	"blue",
	"electric-purple",
	"magenta",
]

loadColor()

function changeColor(colorIndex) {
	forNum(colorsLength, index => {
		body.classList.remove(`rainbow-color-${index}`)
	})
	body.classList.add(`rainbow-color-${colorIndex}`)
	saveColor(colorIndex)
}

function loadColor() {
	const colorIndex = window.localStorage.getItem("rainbow-color-index") || "2"
	body.classList.add(`rainbow-color-${colorIndex}`)
	return colorIndex
}

function saveColor(colorIndex) {
	window.localStorage.setItem("rainbow-color-index", colorIndex)
	window.localStorage.setItem("rainbow-color-name", rainbowColorNames[colorIndex])
}

function Rainbow() {
	return (
		<div id="rainbow" className="grid grid-cols-1 grid-rows-9">
			{repeatFor(colorsLength).map(colorIndex => (
				<div key={colorIndex} className={`rainbow-touch`} onTouchStart={() => {changeColor(colorIndex)}}>
					<div className={`rainbow-block rainbow-${colorIndex} cursor-pointer`} onClick={() => {changeColor(colorIndex)}}></div>
				</div>
			))}
		</div>
	)
}

export default Rainbow
