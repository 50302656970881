// scroll-spy.js v1.1
import { rafInterval } from "./raf-interval"

function scrollSpy(callback, timeInterval = 100, elements = document.querySelectorAll("body > section, body > #root > section")) {
	elements = [...elements] // convert nodeList to array

	onScrollSpy()
	const rafObj = rafInterval(() => {
		onScrollSpy()
	}, timeInterval)

	function onScrollSpy() {
		const positions = [...elements].map((element, index) => {
			const {top, height} = element.getBoundingClientRect()

			return {
				yMin: top,
				yMax: top + height,
				element,
				index
			}
		}),
			positionOnCenter = positions.find(({yMin, yMax}) => {
				const middleOfViewport = window.innerHeight / 2
				return between(middleOfViewport, yMin, yMax)
			})

		if (positionOnCenter) {
			return callback(positionOnCenter)
		}

		function between(number, min, max) {
			return number >= min && number <= max
		}
	}

	function removeScrollSpyEvent() {
		rafObj.stop(true)
	}

	return removeScrollSpyEvent
}

export default scrollSpy