import { rafInterval } from "./raf-interval.js"

// text-scramble.js v1, dependencie: rafInterval
export default function textScramble(options) {
	validateOptions(options)
	const originalOptions = options
	options = normalizeOptions(options)

	let stopped = false,
		result = null

	rafInterval((startTime, rafInitial) => {
		rafInitial.stop()

		const {
			element,
			text: endText,
			delay,
			timeInterval,
			charactersList,
			probabilities
		} = options,
			initialText = element.textContent

		const initialTextLength = initialText.length,
			endTextLength = endText.length,
			maxLength = Math.max(initialTextLength, endTextLength)

		const spans = convertToSpans(element, initialText, endTextLength, maxLength),
			characters = setCharacters(initialText, maxLength)

		let completed = 0

		result = {
			initialText,
			endText,
			options: originalOptions
		}

		forNum(maxLength, index => {
			let newCharacter = null,
				currentCharacter = characters[index]

			const span = spans[index],
				initialCharacter = initialText[index] || "",
				endCharacter = endText[index] || "",
				isToDisplay = index >= initialTextLength && currentCharacter === "",
				isToScramble = currentCharacter !== null && currentCharacter !== endCharacter,
				isToHidden = index >= endTextLength

			// raf for span
			rafInterval((currentTime, rafObj) => {
				if (stopped || currentTime - startTime >= options.maxTime) {
					currentCharacter = setCharacter(spans, characters, index, endCharacter)
					completed = finish(span, completed, rafObj)
				} else if (currentCharacter === endCharacter) {
					completed = finish(span, completed, rafObj)
				} else {
					let noAction = null

					if (initialCharacter === currentCharacter) {
						noAction = probabilities.noActionInFirstCharacter
					} else {
						noAction = probabilities.noActionInScramble
					}

					if (!probabilityOfTrue(noAction)) {
						if (!isToHidden) {
							const toFinish = probabilityOfTrue(probabilities.success)

							if (toFinish) {
								currentCharacter = setCharacter(spans, characters, index, endCharacter)
								completed = finish(span, completed, rafObj)
							} else {
								let toDisplay = false

								if (isToDisplay) {
									toDisplay = probabilityOfTrue(probabilities.display)
								}

								if (isToScramble || (isToDisplay && toDisplay)) {
									newCharacter = scrambleCharacter(span, currentCharacter, charactersList)
									currentCharacter = setCharacter(spans, characters, index, newCharacter)
									if (newCharacter === endCharacter) {
										completed = finish(span, completed, rafObj)
									}
								}
							}
						} else {
							const toHide = probabilityOfTrue(probabilities.hidden)

							if (toHide) {
								span.classList.remove("scramble-to-hidden")
								span.classList.add("scramble-hidden")
								completed = finish(span, completed, rafObj)
							} else {
								newCharacter = scrambleCharacter(span, currentCharacter, charactersList)
								currentCharacter = setCharacter(spans, characters, index, newCharacter)
							}
						}
					}
				}
			}, timeInterval)
		})

		// raf for monitor the end
		rafInterval((startTime, rafObj) => {
			if (stopped || completed === maxLength) {
				rafObj.stop()

				// raf for delay
				rafInterval((currentTime, rafObj) => {
					if (stopped || currentTime - startTime >= delay) {
						rafObj.stop()

						spans.forEach(span => {
							span.classList.remove("scramble-finished", "scramble-flash")
						})

						if (options.callback) {
							options.callback(result)
						}
						if (!stopped) {
							promiseOptions.resolve(result)
						} else {
							promiseOptions.reject(result)
						}
					}
				}, 0)
			}
		}, 0)
	}, 0)

	const promiseOptions = {
		resolve: null,
		reject: null
	}

	const promise = new Promise((resolve, reject) => {
		promiseOptions.resolve = resolve
		promiseOptions.reject = reject
	})

	promise.stop = stopScramble

	return promise

	// FUNCTIONS
	function convertToSpans(element, initialText, endTextLength, maxLength) {
		const spans = []

		element.textContent = ""

		forNum(maxLength, index => {
			const span = document.createElement("span")

			span.classList.add("scramble-character")

			if (index < endTextLength) {
				span.classList.add("scramble-scrambling")
			} else {
				span.classList.add("scramble-to-hidden")
			}
			span.textContent = initialText[index] || ""
			spans.push(span)
		})
		element.append(...spans)

		return spans
	}

	function setCharacters(initialText, maxLength) {
		const characters = []

		forNum(maxLength, index => {
			characters.push(initialText[index] || "")
		})

		return characters
	}

	function setCharacter(spans, characters, index, newCharacter) {
		characters[index] = newCharacter
		spans[index].textContent = newCharacter

		return newCharacter
	}

	function scrambleCharacter(span, currentCharacter, charactersList) {
		let newCharacter = charactersList[Math.floor(Math.random() * charactersList.length)]

		span.classList.add("scramble-scrambling")

		if (newCharacter === currentCharacter) {
			let index = charactersList.indexOf(newCharacter)

			if (index === 0) {
				index = Math.max(0, charactersList.length - 1)
			}
			newCharacter = charactersList[index]
		}

		return newCharacter
	}

	function probabilityOfTrue(chance) {
		return Math.random() <= chance
	}

	function finish(span, completed, rafObj) {
		rafObj.stop()

		const classNames = ["scramble-scrambling"]

		span.classList.remove(...classNames)
		span.classList.add("scramble-finished")

		if (probabilityOfTrue(options.probabilities.flash)) {
			span.classList.add("scramble-flash")
		}

		return ++completed
	}

	function stopScramble() {
		stopped = true
	}

	function forNum(n, fn) {
		for (let i = 0; i < n; i++) {
			fn(i, n)
		}
	}

	function validateOptions(options) {
		const {
			element,
			text
		} = options

		if (options === undefined) {
			throw new Error(`The 'options' argument is required.`)
		}
		if (typeof(options) !== "object") {
			throw new Error(`The 'options' argument must be of type object.`)
		}
		if (element === undefined) {
			throw new Error(`The 'element' property is required.`)
		}
		if (element.nodeType !== 1) {
			throw new Error(`The 'element' property must be a nodeType === 1.`)
		}
		if (text === undefined) {
			throw new Error(`The 'text' property is required.`)
		}
		if (typeof(text) !== "string") {
			throw new Error(`The 'text' property must be an string.`)
		}
	}

	function normalizeOptions(options) {
		const probabilities = {
			success: 0.05,
			display: 0.03,
			hidden: 0.03,
			flash: 0.5,
			noActionInFirstCharacter: 0.8,
			noActionInScramble: 0
		}
		options = {
			...options
		}
		options.probabilities = options.probabilities || {}
		options.probabilities = extend(probabilities, options.probabilities)
		const defaultOptions = {
			delay: 5000,
			timeInterval: 75,
			maxTime: 5000,
			charactersList: "'\"!_@_#_$_%_&_*_(_)_-_+={}[]|\\/<>,.:;"
		}
		options = extend(defaultOptions, options)

		return options

		function extend(objA, objB) {
			for (const prop in objB) {
				objA[prop] = objB[prop]
			}
			return objA
		}
	}
}