import "./TerminalWindow.scss"

import { useState } from "react"

function TerminalWindow() {
	const [tabs, setTabs] = useState([{
		active: true,
		tabName: "App.tsx",
		iconClassName: "devicon-react-original",
	}, {
		active: false,
		tabName: "styles.scss",
		iconClassName: "devicon-sass-original"
	}, {
		active: false,
		tabName: "utils.ts",
		iconClassName: "devicon-typescript-plain"
	}])

	const codeBlocks = [{
		content: "AAA"
	}, {
		content: "BBB"
	}, {
		content: "CCC"
	}]
	codeBlocks.forEach((item, index) => item.active = tabs[index].active)

	function handleClick(index) {
		setTabs(tabs.map((tab, index_) => {
			tab.active = index === index_
			return tab
		}))
	}

	return (
		<div className="TerminalWindow hidden slpMd:grid">
			<div className="terminal-window-inner">
				<div className="terminal-header">
					<div className="tabs-container grid grid-cols-3 cols-min-content place-items-center">
						{tabs.map(({active, tabName, iconClassName}, index) => {
							const className = `tab grid place-items-center ${active ? "active" : ""}`.trim()

							return (
								<div key={index} className={className} onClick={() => handleClick(index)}>
									<p>
										<i className={iconClassName}></i>
										<span>{tabName}</span>
									</p>
								</div>
							)
						})}
					</div>
				</div>
				{codeBlocks.map(({active, content}, index) => {
					const className = `terminal-code tab-${index} ${active ? "active" : ""}`.trim()

					return (
						<pre key={index} className={className}>{content}</pre>
					)
				})}
			</div>
		</div>
	)
}

export default TerminalWindow
