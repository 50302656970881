function isVisibleOnViewport(element) {
	const viewportHeight = window.innerHeight,
		{top, bottom} = element.getBoundingClientRect()
	return top <= viewportHeight && bottom >= 0
}

function isElementInDOM(element) {
	return document.contains(element)
}

function forNum(n, fn) {
	for (let i = 0; i < n; i++) {
		fn(i, n)
	}
}

function repeatFor(length) {
	return Array.from({length}, (_, index) => index)
}

function setTimesout(fn = (time, index) => {}, repeats = [0]) {
	for (let i = 0; i < repeats.length; i++) {
		setTimeout(() => {
			fn(repeats[i], i)
		}, repeats[i])
	}
}

function randomBetween(min, max) {
	max = max + 1 - min
	return min + Math.floor(Math.random() * max)
}

function throttle(fn, delay) {
	let lastCall = -1

	return (...args) => {
		const currentTime = Date.now(),
			elapsedTime = currentTime - lastCall

		if (elapsedTime >= delay) {
			lastCall = currentTime
			fn(...args)
		}
	}
}

function debounce(fn, delay) {
	let lastTimeoutId = null

	return (...args) => {
		const currentTimeoutId = setTimeout(() => {
			if (lastTimeoutId === currentTimeoutId) {
				fn(...args)
			}
		}, delay)
		lastTimeoutId = currentTimeoutId
	}
}

function activeElementAndExecute(element, fn) {
	if (element && element._isActive !== true) {
		element._isActive = true
		fn(element)
	}
}

function getDiffInYears(year, month, day) {
	const now = new Date(),
		date = new Date(`${year}/${month}/${day}`),
		diffInMs = now - date,
		diffInYears = Math.abs(new Date(diffInMs).getUTCFullYear() - 1970)

	return diffInYears
}

function ruleOfThree(a, b, c) {
	return b * c / a
}

function limiter(number, min, max) {
	[min, max] = [Math.min(min, max), Math.max(min, max)]
	return Math.max(Math.min(number, max), min)
}

/* eslint-disable no-unused-vars, no-eval */
function disableConsole() {
	try {
		const console = window.console,
			nativeㅤcode = undefined

		Object.keys(console).forEach(fnName => {
			if (typeof(console[fnName]) === "function") {
				console[fnName] = eval(`(function ${fnName}() { nativeㅤcode })`)
			}
		})
	} catch (e) {}
}
/* eslint-enable no-unused-vars, no-eval */

if (typeof(module) === "object" && typeof(module.exports) === "object") {
	module.exports = {
		isVisibleOnViewport,
		isElementInDOM,
		forNum,
		repeatFor,
		setTimesout,
		randomBetween,
		throttle,
		debounce,
		activeElementAndExecute,
		getDiffInYears,
		ruleOfThree,
		limiter,
		disableConsole
	}
}