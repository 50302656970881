import { useRef, useEffect, useState } from "react"
import { useContextGlobal } from "../../js/globalContext"

import "./AboutMeCarousel.scss"

// complements
import { setTimesout, repeatFor } from "../../js/utils.js"

// libs
import Slider from "react-slick"

// components
import AboutMeSlider from "../AboutMeSlider/AboutMeSlider"
import { slidersLength } from "../AboutMeSlider/AboutMeSlider"

function AboutMeCarousel() {
	const [currentSlider, setCurrentSlider] = useContextGlobal("AboutMe.currentSlider"),
		mainElement = useRef(null),
		slickRef = useRef(null),
		[marginTop, setMarginTop] = useState("0px") // align the slider to the center

	const slickPrev = () => {
		slickRef.current.slickGoTo(currentSlider - 1)
		setCurrentSlider(currentSlider - 1)
	},
		slickNext = () => {
			slickRef.current.slickGoTo(currentSlider + 1)
			setCurrentSlider(currentSlider + 1)
		},
		slickGoTo = value => {
			if (slickRef?.current?.slickGoTo) {
				slickRef.current.slickGoTo(value)
				setCurrentSlider(value)
			}
		}

	useEffect(() => {
		slickGoTo(currentSlider)

		const slickFix = setInterval(() => {
			slickGoTo(currentSlider)
		}, 3333)

		return () => {
			clearInterval(slickFix)
		}
	})

	const slidersInfo = repeatFor(slidersLength).map(index => {
		const isFirst = index === 0,
			isLast = index === slidersLength - 1

		const sliderInfo = {
			index,
			slickActions: {
				slickPrev,
				slickNext,
				slickGoTo,
				isActive: currentSlider === index,
				hasBtnPrev: !isFirst,
				hasBtnNext: !isLast
			}
		}

		return sliderInfo
	})

	const slickSettings = {
		infinite: false,
		arrows: false,
		dots: false,
		draggable: false,
		touchMove: false,
		swiping: false,
		responsive: [{
				breakpoint: 1079, // <= mb
				settings: {
					slidesToShow: slidersLength,
					vertical: true,
					verticalSwiping: true
				}
			},
			{
				breakpoint: 1080, // >= slp
				settings: {
					slidesToShow: 1,
					vertical: false,
					verticalSwiping: false
				}
			}
		]
	}

	useEffect(() => {
		onResize()

		window.addEventListener("resize", onResize)

		function onResize() {
			setTimesout(runResize, [0, 250, 500, 1000, 2500])
		}

		const interval = setInterval(onResize, 1000)

		function runResize() {
			const sliders = [...mainElement.current.querySelectorAll('.slider')],
				slidersHeight = sliders.map(slider => {
					const contentBlock = slider.children[0]
					return contentBlock.clientHeight
				}),
				maxHeight = Math.max(...slidersHeight),
				blockHeight = sliders[0].clientHeight,
				marginTop = Math.max(0, (blockHeight - maxHeight) / 3)

			setMarginTop(`${marginTop}px`)
		}

		return () => {
			window.removeEventListener("resize", onResize)
			clearInterval(interval)
		}
	}, [])

	return (
		<div id="about-me-carousel" ref={mainElement}>
			<Slider {...slickSettings} ref={slickRef}>
				{slidersInfo.map((sliderInfo, keyIndex) => (
					<div key={keyIndex} className="slider">
						<div className="adaptative-padding mb-7" style={{marginTop}}>
							<AboutMeSlider sliderInfo={sliderInfo} />
						</div>
					</div>
				))}
			</Slider>
		</div>
	)
}

export default AboutMeCarousel
