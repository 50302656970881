import { Fragment, useEffect, useRef } from "react"
import { useContextGlobal } from "../../js/globalContext"

import "./PortfolioCarousel.scss"

import { setTimesout } from "../../js/utils"

// libs
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import rafScroll from "../../complements/raf-scroll"
import overlayScrollbarsClickEvent from "../../complements/overlayscrollbars-click-event"

function onScrollSync() {
	const portfolioCarouselElement = document.querySelector("#portfolio-carousel")

	const observer = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			if (mutation.type === "childList" && mutation.addedNodes) {
				mutation.addedNodes.forEach(addedNode => {
					if (addedNode.matches(".os-viewport, [data-overlayscrollbars-contents]") && !addedNode.classList.contains("website-glow")) {
						portfolioOnScroll(addedNode)
					}
				})
			}
		})
	})
	
	observer.observe(portfolioCarouselElement, {
		childList: true,
		subtree: true
	})

	setTimesout(() => {
		const sliders = portfolioCarouselElement.querySelectorAll(".os-viewport, [data-overlayscrollbars-contents]")
		if (sliders) {
			sliders.forEach(element => portfolioOnScroll(element))
		}
	}, [0, 250, 500, 1000, 2500])

	function portfolioOnScroll(element) {
		if (element.classList.contains("carousel-scroll-active")) {
			return
		}
		element.classList.add("carousel-scroll-active")
	
		setTimeout(() => {
			const osScrollbars = element.parentElement.parentElement.querySelectorAll(".os-scrollbar-track"),
				scrollbarHorizontal = osScrollbars[0],
				scrollbarVertical = osScrollbars[1]
		
			let glowElement = undefined

			const websiteNumber = element.closest(".website").getAttribute("class").match(/website-\d+/)[0]
			glowElement = portfolioCarouselElement.querySelector(`.${websiteNumber}.website-glow`)

			overlayScrollbarsClickEvent({
				element,
				scrollbarHorizontal,
				scrollbarVertical
			}, {
				rafScroll
			})
		
			element.addEventListener("scroll", syncScrollsWithGlowElement)
		
			function syncScrollsWithGlowElement() {
				const { scrollTop } = element
				if (glowElement) {
					glowElement.scrollTop = scrollTop
				}
			}
		}, 250)
	}
}

function changePosition({ websites, setWebsites, incrementPosition }) {
	const max = websites.length

	const result = websites.map(website => {
		return {
			...website,
			position: setNewPosition(website.position, incrementPosition, max)
		}
	})
	setWebsites(result)
}

function setNewPosition(position, incrementPosition, max) {
	const newPosition = (position - incrementPosition) % max
	return newPosition >= 0 ? newPosition : max + newPosition // loop
}

function PortfolioCarousel() {
	const [websites, setWebsites] = useContextGlobal("Portfolio.websites")

	const refs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null)
	],
		glowRefs = [
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null),
			useRef(null)
		]

	useEffect(() => {
		onScrollSync()
	}, [])

	function handlePosition(incrementPosition) {
		if (incrementPosition !== 0) {
			changePosition({
				websites,
				setWebsites,
				incrementPosition
			})
		}
	}

	return (
		<div id="portfolio-carousel">
			<div className="websites">
				{websites.map(({img, title, websiteName, position}, index) => {
					const isVisible = position <= 6

					if(isVisible) {
						const incrementPosition = position - 3

						return (
							<Fragment key={index}>
								{[refs, glowRefs].map((refs, keyIndex) => {
									const isGlow = keyIndex === 1,
										isPointer = position !== 3,
										onClick = () => {
											handlePosition(incrementPosition)
										}
									let className = `website website-${position} website-${websiteName}`

									if (isGlow) {
										className += " website-glow"
									}
									if (isPointer) {
										className += " cursor-pointer"
									}

									return (
										<Fragment key={keyIndex}>
											{!isGlow &&
												<div className={className}>
													<OverlayScrollbarsComponent onClick={onClick} ref={refs[index]}>
														{img && <img className="w-full" src={img} title={title} alt={title} />}
													</OverlayScrollbarsComponent>
												</div>
											}
											{isGlow && img &&
												<div className={className} ref={refs[index]}>
													<img className="w-full" src={img} title={title} alt={title} />
												</div>
											}
										</Fragment>
									)
								})}
							</Fragment>
						)
					}

					return (
						<Fragment key={index} />
					)
				})}
			</div>
		</div>
	)
}

export default PortfolioCarousel
