// overlayscrollbars-click-event.js v1.1, dependencies: rafScroll
function overlayScrollbarsClickEvent(scrollbarsElement, options = {}) {
	let element, scrollbarHorizontal, scrollbarVertical

	if(scrollbarsElement.elements) { // native
		const elements = scrollbarsElement.elements()

		element = elements.target
		scrollbarHorizontal = elements.scrollbarHorizontal
		scrollbarVertical = elements.scrollbarVertical
	} else { // react
		element = scrollbarsElement.element
		scrollbarHorizontal = scrollbarsElement.scrollbarHorizontal
		scrollbarVertical = scrollbarsElement.scrollbarVertical
	}

	if (!element.matches(".os-viewport")) {
		element = element === document.body ? document.documentElement : [...element.children].find(element => element.matches(".os-viewport"))
	}
	scrollbarHorizontal = scrollbarHorizontal.track || scrollbarHorizontal
	scrollbarVertical = scrollbarVertical.track || scrollbarVertical
	const rafScroll = options.rafScroll || window.rafScroll

	scrollbarHorizontal.addEventListener("mousedown", event => scrollbarClick(event, false))
	scrollbarVertical.addEventListener("mousedown", event => scrollbarClick(event, true))

	function scrollbarClick({target: scrollElement, clientY, clientX}, isVertical) {
		if(!isVertical && scrollElement === scrollbarHorizontal) {
			const scrollbarHorizontal = scrollElement,
				clickPosition = clientX - scrollbarHorizontal.getBoundingClientRect().left,
				scrollbarSize = scrollbarHorizontal.offsetWidth,
				clickRatio = clickPosition / scrollbarSize,
				elementSize = element.scrollWidth,
				scrollPosition = elementSize * clickRatio - window.innerWidth / 2

			rafScroll(element, {
				x: scrollPosition
			})
		} else if (isVertical && scrollElement === scrollbarVertical) {
			const scrollbarVertical = scrollElement,
				clickPosition = clientY - scrollbarVertical.getBoundingClientRect().top,
				scrollbarSize = scrollbarVertical.offsetHeight,
				clickRatio = clickPosition / scrollbarSize,
				elementSize = element.scrollHeight,
				scrollPosition = elementSize * clickRatio - window.innerHeight / 2

			rafScroll(element, {
				y: scrollPosition
			})
		}
	}
}

export default overlayScrollbarsClickEvent