import "./GlowShadow.scss"

function GlowShadow({children, className = "", style = {}}) {
	return (
		<div className={`glow-shadow ${className}`.trim()} style={style}>
			{children}
			<div className="glow">
				{children}
			</div>
		</div>
	)
}

export default GlowShadow
