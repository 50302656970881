import { useEffect, useRef } from "react"
import { useContextGlobal } from "../../js/globalContext"

import "./Scramble.scss"

import fontOnload from "../../complements/font-onload.js"
import textScramble from "../../complements/text-scramble.js"
import { rafInterval } from "../../complements/raf-interval.js"

function createScrambleObj(elements, textList) {
	const mainOptions = {
		maxTime: 5000,
		probabilities: {
			success: 0.125
		}
	}

	const scrambleList = elements.map((element, index) => {
		const texts = textList[index]
		return texts.map(text => {
			return {
				element,
				...mainOptions,
				text
			}
		})
	})

	return scrambleList
}

function runScramble(refs, textList) {
	const elements = refs.map(ref => {
		return ref.current
	}).filter(element => element !== null)
	const scrambleList = createScrambleObj(elements, textList)

	loopScramble(scrambleList)
}

function loopScramble(scrambleList, index = -1) {
	const firstScramble = scrambleList[0],
		maxIndex = firstScramble.length
	index = (index + 1) % maxIndex // loop
	let completed = 0

	scrambleList.forEach(scramble => {
		textScramble(scramble[index]).then(() => {
			completed++
		})
	})

	rafInterval((_, rafObj) => {
		if (completed === scrambleList.length) {
			rafObj.stop()

			loopScramble(scrambleList, index)
		}
	}, 0)
}

function Scramble({textList, className}) {
	if (!Array.isArray(textList[0])) { // convert ["string"] to [["string"], ...]
		textList = [textList]
	}
	if (!Array.isArray(className)) { // repeat string for each textList
		className = textList.map(() => className) // ["string"] to ["string", "string", ...]
	}
	const ref1 = useRef(null),
		ref2 = useRef(null),
		refs = [ref1, ref2]

	const [fontName] = useContextGlobal("fontName"),
		maxTime = 2500

	useEffect(() => {
			const firstElement = refs[0] ? refs[0].current : null
			let hasLoaded = true,
				isActive = false

			if (firstElement) {
				isActive = firstElement.noRepeat
			}

		textList.forEach((_, index) => {
			const thisElement = refs[index]?.current
			if (!thisElement) {
				hasLoaded = false
			}
		})

		if (hasLoaded && !isActive) {
			firstElement.noRepeat = true

			fontOnload(fontName, maxTime).then(() => {
				runScramble(refs, textList)
			})
		}
	})

	return (
		<>
			{textList.map((_, index) => (
				<h1 key={index} className={`scramble ${className[index] || ""}`.trim()} ref={refs[index]}>.</h1>
			))}
		</>
	)
}

export default Scramble
