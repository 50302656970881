import "./Home.scss"

import SocialContacts from "../SocialContacts/SocialContacts"
import HomeSpacer from "../HomeSpacer/HomeSpacer"
import Divider from "../Divider/Divider"
import HomeBg from "../HomeBg/HomeBg"
import Scramble from "../Scramble/Scramble"
import TerminalWindow from "../TerminalWindow/TerminalWindow"

const textList = [
	[
		"Desenvolvedor",
		"8 anos de",
		"Formado em",
		"Especialista em",
		"Freelancer"
	],
	[
		"Front-end Sênior",
		"Experiência",
		"Design gráfico",
		"E-commerce",
		"Criativo"
	]
],
	className = ["rainbow-color", "color-white"]


function Home() {
	return (
		<section id="home">
			<div className="main-container">
				<SocialContacts />
				<div className="home-block grid-center">
					<div className="w-full">
						<Scramble className={className} textList={textList} />
						<HomeSpacer />
					</div>
				</div>
			</div>
			<TerminalWindow />
			<Divider className="divider-bottom" />
			<HomeBg />
		</section>
	)
}

export default Home
