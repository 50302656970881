import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { GlobalProvider } from "./js/globalContext"

import "./index.scss"

// complements
import rafScroll from "./complements/raf-scroll.js"
import overlayScrollbarsClickEvent from "./complements/overlayscrollbars-click-event.js"

// libs
import { OverlayScrollbars } from "overlayscrollbars"
// redux
// import { store } from "./js/redux/store.js"
// import { Provider } from "react-redux"

// components
import App from "./components/App/App"
import ScreenFlag from "./components/ScreenFlag/ScreenFlag"

import { disableConsole } from "./js/utils.js"

const isDev = window.location.hostname === "localhost",
	isProd = !isDev

if (isDev) {
	document.body.classList.add("localhost")
}

if (isProd) {
	disableReactDevTools()
	disableKeyF12()
	disableConsole()
	disableEdgeMiniMenu()
}

function Index() {
	useEffect(() => {
		const scrollbarsBody = OverlayScrollbars(document.body, {
			update: {
				ignoreMutation: () => {
					return true // ignore all changes
				}
			}
		})
		overlayScrollbarsClickEvent(scrollbarsBody, {
			rafScroll
		})
	}, [])

	return (
		<React.StrictMode>
			{/* <Provider store={store}> redux */}
				<GlobalProvider>
					<ScreenFlag />
					<App />
				</GlobalProvider>
			{/* </Provider> */}
		</React.StrictMode>
	)
}

const root = ReactDOM.createRoot(document.querySelector("#root"))
root.render(<Index />)

function disableReactDevTools() {
	const reactDevTools = window.__REACT_DEVTOOLS_GLOBAL_HOOK__
	if (typeof(reactDevTools) === "object") {
		for (const [key, value] of Object.entries(reactDevTools)) {
			reactDevTools[key] = typeof(value) === "function" ? () => {} : null
		}
	}
}

function disableKeyF12() {
	window.addEventListener("keydown", event => {
		if (event.key === "F12") {
			event.preventDefault()
		}
	})
}

function disableEdgeMiniMenu() {
	if (/Edg\//.test(window.navigator.userAgent)) { // if it's edge (chromium-based)
		window.addEventListener("mouseup", event => event.preventDefault())
		window.addEventListener("dblclick", () => window.getSelection().empty())
	}
}