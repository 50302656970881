import "./AboutMe.scss"

import meImage from "../../imgs/about-me/me.jpg"

// components
import AboutMeMenu from "../AboutMeMenu/AboutMeMenu"
import AboutMeContent from "../AboutMeContent/AboutMeContent"

function AboutMe() {
	return (
		<section id="about-me" className="py-10rem">
			<div className="main-container py-3rem">
				<div className="about-me-padding">
					<div className="block h-smart slp:grid slp:grid-cols-12">
						<div className="adaptative-padding h-smart slp:col-span-6">
							<div className="block-me-img h-smart">
								<img className="me-img inline h-smart" src={meImage} title="Luis Lobo" alt="Luis Lobo" />
								<AboutMeMenu />
							</div>
						</div>
						<div className="h-smart slp:col-span-6">
							<AboutMeContent />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default AboutMe
