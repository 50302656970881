import { useEffect, useState } from "react"

import "./UnderConstruction.scss"

function UnderConstruction() {
	const [scrollY, setScrollY] = useState(window.scrollY),
		[visibility, setVisibility] = useState(true),
		[forceUpdate, setForceUpdate] = useState(false)

	function checkVisiblity() {
		setForceUpdate(!forceUpdate)
	}

	const maxDistance = window.innerHeight / 4

	if (scrollY >= maxDistance && visibility === true) {
		if (performance.now() > 10000) {
			setVisibility(false)
		}
	} else if (scrollY < maxDistance && visibility === false) {
		setVisibility(true)
	}

	useEffect(() => {
		window.addEventListener("scroll", onScroll)

		function onScroll() {
			setScrollY(window.scrollY)
		}

		const timeout = setTimeout(() => {
			checkVisiblity()
		}, 1000)

		return () => {
			window.removeEventListener("scroll", onScroll)
			clearTimeout(timeout)
		}
	})

	const className = `hidden slp:grid place-items-center ${!visibility ? "opacity-0 drop" : "opacity-1"}`.trim()

	return (
		<div id="UnderConstruction" className={className}>
			<p>Site em desenvolvimento (versão beta).</p>
			<div className="background-color rainbow-bg"></div>
		</div>
	)
}

export default UnderConstruction