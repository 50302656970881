import { useContextGlobal } from "../../js/globalContext"

import "./AboutMeMenu.scss"

import IconPresentation from "../../imgs/about-me/presentation.svg"
import IconCareer from "../../imgs/about-me/career.svg"
import IconProfessionalLife from "../../imgs/about-me/professional-life.svg"
import IconPersonalLife from "../../imgs/about-me/personal-life.svg"
import IconHobbies from "../../imgs/about-me/hobbies.svg"

const imgIconList = [
	{
		name: "Apresentação",
		imgIcon: IconPresentation
	},
	{
		name: "Carreira",
		imgIcon: IconCareer
	},
	{
		name: "Vida Profissional",
		imgIcon: IconProfessionalLife
	},
	{
		name: "Vida Pessoal",
		imgIcon: IconPersonalLife
	},
	{
		name: "Hobbies",
		imgIcon: IconHobbies
	}
]

function AboutMeMenu() {
	const [currentSlider, setCurrentSlider] = useContextGlobal("AboutMe.currentSlider")

	return (
		<div id="about-me-menu" className="hidden slp:grid">
			<div className="block-icons">
				{imgIconList.map(({name, imgIcon}, index) => {
					const maskStyle = {
						WebkitMask: `url(${imgIcon})`,
						mask: `url(${imgIcon})`
					}
					let className = "menu-icon grid-center rainbow-fill-on-hover bg-black"
					if (currentSlider === index) {
						className = className.replace("menu-icon", "menu-icon rainbow-fill-active")
					}

					return (
						<div key={index} className={className} style={maskStyle} onClick={() => setCurrentSlider(index)}>
							<img className="img-icon" src={imgIcon} alt={name} />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default AboutMeMenu
