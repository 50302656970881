import "./Btn.scss"

function Btn({className = '', children, ...props}) {
	className = `btn flex items-center ${className}`.trim()

	return (
		<button className={className} {...props}>
			{children}
			<span className="btn-background-color"></span>
		</button>
	)
}
export default Btn