import { useEffect, useRef } from "react"

import "./CodeWriterOnBackground.scss"

// complements
import { forNum, randomBetween } from "../../js/utils.js"
import { rafInterval } from "../../complements/raf-interval.js"

function CodeWriterOnBackground({ options = {} }) {
	const refElement = useRef(null)

	useEffect(() => {
		const mainElement = refElement.current

		if (!mainElement.classList.contains("active")) {
			mainElement.classList.add("active")

			writeCode({
				...options,
				mainElement
			})
		}

		async function writeCode(options) {
			options = normalizeOptions(options)
			const {
				mainElement,
				blocksLength,
				minSpeed,
				maxSpeed,
				delayToHidden,
				delayToRepeat,
				minLines,
				maxLines
			} = options,
				elementsList = createElements(mainElement, blocksLength)
			let maxHeight = 0,
				maxWidth = 0,
				allCode = await extractCodes()
	
			allCode = allCode.split("\n")
	
			elementsList.forEach(([preBlock, spanCode]) => {
				const speed = randomBetween(minSpeed, maxSpeed)
				let firstTime = true
	
				rafInterval((_, rafInitial) => {
					rafInitial.pause()
	
					// reset
					spanCode.textContent = ""
					setPosition(preBlock, mainElement, maxHeight, maxWidth)
	
					let randomLine = randomBetween(0, allCode.length - maxLines),
						code = allCode.slice(randomLine, randomLine + randomBetween(minLines, maxLines))
					code = code.join("\n")
					let characterIndex = -1
	
					if (firstTime) {
						firstTime = false
						characterIndex = randomBetween(-1, parseInt(code.length / 2));
						spanCode.textContent = code.substring(0, characterIndex)
					}
	
					preBlock.classList.add("code-writer-show")
	
					rafInterval((_, rafCharacter) => {
						characterIndex++
	
						if (characterIndex < code.length) {
							const character = code[characterIndex]
	
							spanCode.textContent += character
						} else {
							rafCharacter.stop()
	
							maxHeight = Math.max(maxHeight, preBlock.clientHeight)
							maxWidth = Math.max(maxWidth, preBlock.clientWidth)
	
							rafInterval((_, rafHidden) => {
								rafHidden.stop()
	
								preBlock.classList.remove("code-writer-show")
	
								rafInterval((_, rafDelay) => {
									rafDelay.stop()
									rafInitial.play() // loop
								}, delayToRepeat)
							}, delayToHidden)
						}
					}, speed)
				}, 0)
			})
		}
	}, [options])

	function normalizeOptions(options = {}) {
		let defaultOptions = {
				mainElement: document.querySelector("#code-writer-on-background"),
				blocksLength: 12,
				minSpeed: 33,
				maxSpeed: 333,
				delayToHidden: 1500,
				delayToRepeat: 3000,
				minLines: 4,
				maxLines: 12
			},
			newOptions = {
				...defaultOptions,
				...options
			}
	
		return newOptions
	}
	
	function setPosition(preBlock, mainElement, maxHeight, maxWidth) {
		let height = mainElement.clientHeight,
			width = mainElement.clientWidth
		const top = randomBetween(-(maxHeight / 2), height - (maxHeight / 2)) + "px",
			left = randomBetween(-(maxWidth / 2), width - (maxWidth / 2)) + "px"
	
		preBlock.setAttribute("style", `top: ${top}; left: ${left};`)
	}
	
	function createElements(mainElement, blocksLength) {
		const elementsList = []
	
		forNum(blocksLength, () => {
			const preBlock = document.createElement("pre"),
				spanCode = document.createElement("span")
	
			preBlock.classList.add("pre-code")
			spanCode.classList.add("span-code")
	
			preBlock.append(spanCode)
			mainElement.append(preBlock)
	
			elementsList.push([
				preBlock,
				spanCode
			])
		})
		return elementsList
	}
	
	async function extractCodes() {
		const response = await fetch("/others/lorem-codes.txt")
		const allCode = await response.text()
		return allCode
	}

	return (
		<div id="code-writer-on-background" ref={refElement}>
		</div>
	)
}

export default CodeWriterOnBackground
