import {useEffect, useRef, useState} from "react"

import "./Menu.scss"

// complements
import scrollSpy from "../../complements/scroll-spy"
import rafScroll from "../../complements/raf-scroll"

// components
import GlowShadow from "../GlowShadow/GlowShadow"

function Menu() {
	const btnInfos = [
		{
			text: "Home",
			element: document.querySelector("#home")
		},
		{
			text: "Sobre mim",
			element: document.querySelector("#about-me")
		},
		{
			text: "Currículo",
			textMobile: "CV"
		},
		{
			text: "Portfólio",
			element: document.querySelector("#portfolio")
		},
		{
			text: "Inteligência Artificial",
			textMobile: "IA",
			element: document.querySelector("#artificial-intelligence")
		},
		{
			text: "Contato",
			element: document.querySelector("#contact")
		}
	]

	const menuContainerRef = useRef(null),
		[left, setLeft] = useState("7px"),
		[width, setWidth] = useState("0px"),
		[activeMenuIndex, setActiveMenuIndex] = useState(0),
		htmlElement = document.documentElement

	function clickMenu(index) {
			const {
				offsetLeft: x,
				offsetTop: y
			} = btnInfos[index].element

		rafScroll(htmlElement, {
			x,
			y
		})
	}

	useEffect(() => {
		const removeScrollSpyEvent = scrollSpy(onScrollSpy)

		function onScrollSpy({index}) {
			if (index >= 2) { // jump CV button
				index++
			}

			const menuContainer = menuContainerRef.current,
				element = menuContainer.children[index],
				left = element.offsetLeft,
				width = element.clientWidth

			setActiveMenuIndex(index)
			setLeft(`${left}px`)
			setWidth(`${width}px`)
		}

		return () => {
			removeScrollSpyEvent()
		}
	}, [left, width, activeMenuIndex])

	return (
		<nav id="menu" className="w-full grid-center">
			<GlowShadow>
				<div className="menu-container" ref={menuContainerRef}>
					{btnInfos.map((content, index) => {
						const isActive = activeMenuIndex === index ? "active" : "",
							className = `btn-menu ${isActive}`.trim()

						let onClick = () => clickMenu(index)
						if (index === 2) {
							onClick = () => {
								const rainbowColorName = localStorage.getItem("rainbow-color-name") || "orange"
								window.open(`${window.location.origin}/cv/curriculo-luis-lobo-${rainbowColorName}.pdf`)
							}
						}

						return (
							<button key={index} index={index} className={className} onClick={onClick}>
								<small className="hidden slp:block">
									{content.text}
								</small>
								<small className="block slp:hidden">
									{content.textMobile || content.text}
								</small>
							</button>
						)
					})}
					<GlowShadow className="btn-glow" style={{left, width}}>
						<div className="btn-menu-bg rainbow-bg"></div>
					</GlowShadow>
				</div>
			</GlowShadow>
		</nav>
	)
}

export default Menu
